import toast from "react-hot-toast";
import axios, { AxiosRequestConfig } from "axios";
import { Configs, ECoins } from "./config";

export const sleep = (ms: number) => new Promise(r => setTimeout(() => r(''), ms));
export const TextEllipse = (text: string, maxLength: number): string => text.length < maxLength ? text : text.substring(0, maxLength) + '...';
export const randInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
export const Time = () => Math.floor(Number((Date.now() / 1000).toFixed(0)));

export const IsAlphaNumeric = (str: string): boolean => {
   let code, i, len;
   for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (!(code > 47 && code < 58) && // numeric (0-9)
         !(code > 64 && code < 91) && // upper alpha (A-Z)
         !(code > 96 && code < 123)) { // lower alpha (a-z)
         return false;
      }
   }
   return true;
};


export const convertDate = (inputDate: number): string => {
   const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
   ];

   const date = new Date(inputDate * 1000);
   const day = date.getUTCDate();
   const month = months[date.getUTCMonth()];
   const year = date.getUTCFullYear();
   const hours = ("0" + date.getUTCHours().toString()).slice(-2);
   const minutes = ("0" + date.getUTCMinutes().toString()).slice(-2);

   return `${day} ${month}, ${year} ${hours}:${minutes}`;
}

export const ValidateWallet = (wallet?: string): boolean => {
   if (!wallet || wallet[0] !== 'T') return false;
   const len = wallet.length;
   if (len < 30 || len > 40 || !IsAlphaNumeric(wallet)) return false;
   return true;
}

export const CheckIfWalletOrLogout = (): string | null => {
   const wallet = localStorage.getItem('wallet');
   if (!wallet || !ValidateWallet(wallet)) {
      localStorage.removeItem('wallet');
      window.location.reload();
      return null;
   }
   return wallet
}

export const AccountToCurrencySymbol = (account: ECoins): string => account.split("_")[0];

export const CopyToClipboard = (e: string | React.MouseEvent<HTMLInputElement, MouseEvent>) => {
   let t;
   if (typeof e === 'string') {
      t = e;
   }
   else {
      const copyText = e.target as HTMLInputElement;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      t = copyText.value;
      copyText.setSelectionRange(0, 0);
   }
   try {
      navigator.clipboard.writeText(t);
      toast.success('Copied to clipboard')
   }
   catch (e) {
      toast.error('Could not copy');
   }
}


const MyAxios = {
   get: async <ResponseDataType>(url: string, headers: null | string = null)
      : Promise<ResponseDataType | null> => await MyAxios.getData('GET', url, headers, ""),

   delete: async <ResponseDataType>(url: string, headers: null | string = null)
      : Promise<ResponseDataType | null> => await MyAxios.getData('DELETE', url, headers, ""),

   post: async <ResponseDataType>(url: string, headers: null | string = null, body: any = null)
      : Promise<ResponseDataType | null> => await MyAxios.getData('POST', url, headers, body),

   patch: async <ResponseDataType>(url: string, headers: null | string = null, body: any = null)
      : Promise<ResponseDataType | null> => await MyAxios.getData('PATCH', url, headers, body),

   getData: async <ResponseDataType>(
      method: string,
      url: string,
      headers: null | string,
      body: any
   ): Promise<ResponseDataType | null> => {
      try {
         const jwt = localStorage.getItem("jwt");
         const config: AxiosRequestConfig = { url: Configs.API_URL + url, method };

         if (!headers) headers = '';
         if (jwt) headers += `;authorization:${jwt}`;
         const headsArray = headers.split(';').map(head => head.split(':')).filter(el => typeof el === 'object' && el.length === 2);
         config.headers = headsArray.reduce((obj: { [key: string]: string }, [key, value]) => {
            obj[key] = value;
            return obj;
         }, {});
         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
         if (method !== 'GET' && method !== 'DELETE' && body) config.data = body;
         // eslint-disable-next-line @typescript-eslint/no-unsafe-return
         return (await axios(config)).data;
      }
      catch (e: any) {
         console.log(e);

         // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
         if (e?.response?.data) return e.response.data;
         // eslint-disable-next-line @typescript-eslint/no-unsafe-return
         return e;
      }
   }
}