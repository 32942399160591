import { useContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { MyContext } from "./App";
import { ICreateResponse, IR_Create } from "./ServerResponse";
import { Configs, ECoins } from "./config";
import { CopyToClipboard } from "./Utils";
import Shimmer from "./Shimmer";

const CreateDeposit = () => {

   const { user, setCurrentTab, currentTab, UpdateUserData } = useContext(MyContext);
   useEffect(() => { (currentTab !== "Create") && setCurrentTab("Create") }, [currentTab, setCurrentTab]);
   const [currentCoin] = useState<ECoins>("TRX");
   const [, setCanChange] = useState<boolean>(true);

   const [walletData, setWalletData] = useState<IR_Create | null | false>(null);
   useEffect(() => console.log('walletData', walletData), [walletData]);

   const [isExist, setIsExist] = useState<boolean>(false);


   useEffect(() => {
      if (!user) UpdateUserData();
   }, [user, UpdateUserData]);

   useEffect(() => {
      if (!user) return;
      let ourReq = axios.CancelToken.source();
      (async () => {
         try {
            setWalletData(false);
            setCanChange(false);
            const data = (await axios.get(`${Configs.API_URL}/create?coin=${currentCoin}`, { headers: { jwt: user.wallet }, cancelToken: ourReq.token })).data as ICreateResponse;
            if (!data) return setWalletData(null);
            if (data?.text === 'exists') {
               setIsExist(true);
               return;
            }
            else {
               setIsExist(false);
            }
            setWalletData(data.data);
         }
         catch (_e) {
            setWalletData(null);
            const e = _e as any;
            if (e?.response?.data?.text) {
               toast.error(e?.response?.data?.text || "unknown error");
            }
            return;
         }
         finally {
            setCanChange(true);
         }
      })();
      return () => ourReq.cancel();
   }, [user, currentCoin]);

   useEffect(() => console.log('isExist', isExist), [isExist]);

   useEffect(() => {
      if (isExist) {
         console.log('isExist', isExist);
      }
   }, [isExist])


   return (
      <div className="make-deposit">
         <div className="container">
            <div className="deposit">
               <div className="deposit__title">
                  Make a deposit
               </div>

               {!isExist ?
                  <>
                     <div className="deposit__choise">
                        <div className="deposit__inner">
                           <div className="deposit__text deposit__row">
                              <div className="deposit__col">
                                 <span className="deposit__col-title">
                                    Minimum amount for deposit
                                 </span>
                                 <span className="accent" id="min-amount">
                                    5 TRX
                                 </span>
                              </div>
                              <div className="deposit__col">
                                 <span className="deposit__col-title">
                                    Maximum amount for deposit
                                 </span>
                                 <span className="accent" id="need-confirms">
                                    5 TRX
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="deposit__address" id="crypto-form">
                        <label className="deposit__label" htmlFor="address">Address</label>
                        <div className="deposit__inner">
                           <div className="deposit__edit">
                              {
                                 walletData ? <input className="deposit__input"
                                    id="callback-address"
                                    readOnly
                                    type="text"
                                    style={{ cursor: `copy` }}
                                    value={walletData.address}
                                    onClick={e => CopyToClipboard(e)}
                                 /> : <Shimmer data={{ w: '100%', h: 37 }} />
                              }
                              <div className="deposit__qr-block">
                                 <div className="deposit__qr-img">
                                    {
                                       walletData ? (
                                          <img id="callback-qr" src={`https://chart.googleapis.com/chart?cht=qr&chs=120x120&chl=${walletData.address}&chld=|0`} className="qrcode-holder" alt="qr code" />
                                       ) : (
                                          <Shimmer data={{ w: 120, h: 120 }} />
                                       )
                                    }
                                 </div>
                                 <div className="deposit__text">
                                    <span className="deposit__subtitle">Waiting for your deposit</span>
                                    <p>As soon as we receive the necessary confirmations, your deposit will be displayed on the page "My Deposits".</p>
                                 </div>
                              </div>
                           </div>
                           <div className="deposit__text deposit__info">
                              <p>Waiting and deposit procedure does not take more than 15 minutes on average.</p>
                              <p>If the amount sent by you is less than the minimum deposit amount for the selected payment system, such payment will be considered as a donation.</p>
                              <strong style={{ color: '#ff6f65' }}>Do not create multiple deposits, only one will be activated if you do.</strong>
                              <br />
                              <strong style={{ color: '#ff6f65' }}>You can create new deposit after each 8 hours</strong>
                           </div>
                        </div>
                     </div>
                  </> : <>
                     <p style={{ marginTop: '20px', color: '#ff6f65' }}>
                        You already have a deposit created, you can create another deposit only after 8 hours of your last deposit
                     </p>
                  </>
               }
            </div>
         </div>
      </div>
   );
};

export default CreateDeposit;