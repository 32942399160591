import { EAccounts } from "./ServerResponse";


export const Configs = {
   // API_URL: 'http://localhost:3034/api/v2',
   API_URL: 'https://api.trx5.ltd/api/v2',
   SupportEmail: 'support@trx5.ltd',
}

export const GetUrl = (account: EAccounts, hash: string): string => {
   if (!account) return '#';
   if (account === 'BTC') return `https://blockchain.info/tx/${hash}`;
   if (account === 'LTC') return `https://live.blockcypher.com/ltc/tx/${hash}`;
   if (account === 'DOGE') return `https://dogechain.info/tx/${hash}`;
   if (account === 'TRX') return `https://tronscan.org/#/transaction/${hash}`;
   if (account === 'ETH') return `https://etherscan.io/tx/${hash}`;
   if (account === 'BNB') return `https://bscscan.com/tx/${hash}`;

   const s = account.split("_")[1];
   if (!s) return '#';

   if (s === "TRC20") return `https://tronscan.org/#/transaction/${hash}`;
   if (s === "ERC20") return `https://etherscan.io/tx/${hash}`;
   if (s === "BEP20") return `https://bscscan.com/tx/${hash}`;

   return '#';
}

export const GetAccount = (coin: EAccounts): string => coin.split("_")[0];

export type ECoins = 'BTC' | 'LTC' | 'DOGE' | 'ETH' | 'TRX' | 'USDT_TRC20' | 'USDT_ERC20';
export const Coins: ECoins[] = ['BTC', 'LTC', 'DOGE', 'ETH', 'TRX', 'USDT_TRC20', 'USDT_ERC20'];