import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { MyContext } from './App';
import { IsAlphaNumeric, Time, ValidateWallet, convertDate } from './Utils';
import { Configs, GetAccount, GetUrl } from './config';
import { IHomeResponse, ILoginResponse, IR_Home } from './ServerResponse';
import Shimmer from './Shimmer';


const Home = () => {
   const [isAuthing, setIsAuthing] = useState<boolean>(false);
   const { user, setUser } = useContext(MyContext);

   const { refCode } = useParams();
   const [referCode, setReferCode] = useState<string | null>(null);
   useEffect(() => {
      if (refCode) {
         if (!IsAlphaNumeric(refCode)) return;
         if (refCode.length !== 5) return;
         setReferCode(refCode);
      }
   }, [refCode]);


   const Register = async () => {
      try {
         setIsAuthing(true);
         const wallet = (document.getElementById("wallet") as any)?.value;
         console.log({ wallet })
         if (!ValidateWallet(wallet)) return toast.error('Invalid TRX wallet');
         const postData = { wallet } as any;
         if (referCode) postData.refCode = referCode;
         const data = (await axios.post(`${Configs.API_URL}/auth`, postData)).data as ILoginResponse;
         if (!data.data.wallet) return toast.error('Unknown error');
         localStorage.setItem("wallet", data.data.wallet);
         setUser(data.data);
         window.location.href = '/cabinet';
      }
      catch (e) {
         toast.error('Unknown error');
      }
      finally {
         setIsAuthing(false);
      }
   }

   const [loggedIn, setLoggedIn] = useState<boolean>(false);

   useEffect(() => {
      if (user?.refcode) return setLoggedIn(true);
      setLoggedIn(ValidateWallet(localStorage.getItem('wallet') || undefined));
   }, [user?.refcode]);

   const [home, setHome] = useState<IR_Home | null>(null);
   const [homeLast, setHomeLast] = useState<number>(0);
   const [online, setOnline] = useState<number>(0);
   useEffect(() => {
      let ourReq = axios.CancelToken.source();
      if (homeLast < Time()) {
         setHome(null);
         (async () => {
            try {
               const data = (await axios.get(`${Configs.API_URL}/home`, { cancelToken: ourReq.token })).data as IHomeResponse;
               if (!data.data) return;
               setHome(data.data);
               setHomeLast(Time() + 5);
               setOnline(Math.floor(Math.random() * (1000 - 400 + 1)) + 400);
            }
            catch (e) {
               // console.log(e);
            }
         })();
      }
      return () => ourReq.cancel();
   }, [homeLast])


   return (
      <div className="wrapper">
         <header className="header">
            <div className="header-top js-navbar">
               <div className="container">
                  <div className="header-top__inner">
                     <a href="/" className="logo">
                        <img src="/images/logo.png" alt="trx5" />
                     </a>
                     <div className="nav-menu js-nav-menu">
                        <ul className="nav-menu__list js-anchors">
                           <li><a href="/">Home</a></li>
                           <li><a href="#about">About us</a></li>
                           <li><a href="#faq">Help</a></li>
                           <li><a href="https://t.me/+AilxS90PX6RkN2Zl" target="_blank" rel="noreferrer">Telegram channel</a></li>
                        </ul>
                     </div>
                     <div className="burger-btn js-burger-btn">
                        <span className="burger-btn__line"></span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="header__time time">
               <div className="container">
                  <div className="time__inner">
                     <span className="time__text" style={{ display: 'flex', alignItems: 'center' }}>
                        Start date &nbsp;
                        <b>{home ? (new Date(home.start_date * 1000).toISOString().split('T')[0]) : <Shimmer data={{ w: 100, h: 20 }} />}</b>
                     </span>
                     <span className="time__text">Current online <b>
                        {home ? online : <Shimmer data={{ w: 100, h: 20 }} />}
                     </b></span>
                  </div>
               </div>
            </div>
            <div className="hero header__hero home">
               <div className="container">
                  <div className="hero__inner">
                     <div className="hero-chart hero__chart home" style={{ borderRadius: '50px', overflow: 'hidden', marginRight: '20px' }} id="coinmarketcap">
                        <img src="/images/banner.webp" alt="" />
                     </div>
                     <div className="starting">
                        <h1 className="starting__title">DOUBLE YOUR MONEY FOR 24 HOURS</h1>
                        {
                           !loggedIn ? (
                              <>
                                 <span className="starting__text">Please enter your tron address and login:</span>
                                 <div className="starting-form">
                                    <div className="starting-form__edit">
                                       <input type="text" name="address" placeholder="Your trx wallet" id="wallet" className="starting-form__input" />
                                    </div>
                                    <button className="button button_green" onClick={Register} disabled={isAuthing}>
                                       <i className="fas fa-key"></i> {isAuthing ? 'Wait...' : 'Log in'}
                                    </button>
                                 </div>
                              </>
                           ) : (
                              <div className="starting-form">
                                 <a className="button button_green" href='/cabinet'>
                                    <i className="fas fa-key"></i> Cabinet
                                 </a>
                              </div>
                           )
                        }
                     </div>
                  </div>
               </div>
            </div>
            <video autoPlay muted loop className="header__video">
               <source src="/video/video-bg.mp4" type="video/mp4" />
            </video>
            <img className="header__wave" src="/images/header-wave.svg" alt="" />
         </header>
         <section className="about" id="about">
            <div className="container">
               <div className="about__inner">
                  <div className="story">
                     <h3 className="title-secondary about__title">About us</h3>
                     <p className="paragraph">
                        Our company is focused on the development and exploitation of highly liquid areas in the forex market, stock markets of leading economic countries and unions, as well as on popular cryptocurrency exchanges.
                     </p>
                     <p className="paragraph">
                        The most important priority of Trx5 company is absolute transparent reporting on the work done, as well as the trust of our esteemed clients.
                     </p>
                     <p className="paragraph">
                        At the initial stage of our activities, we raise funds to support the existing eco-system Trx5 from crowdfunding companies and ordinary users on the Internet. At the same time, we offer not only to take part in the development of the project on a charitable basis, but also to pay a fair interest for maintaining our activities.
                     </p>
                  </div>
                  <div className="counters">
                     <div className="counters__row">
                        <div className="counter counters__counter counter_blue">
                           <span className="counter__numeral">+100% in 24 hours</span>
                           <span className="counter__title">Automatic payments after 24 hours.</span>
                        </div>
                     </div>
                     <div className="counters__row">
                        <div className="counter counters__counter counter_dark counter_two">
                           <span className="counter__numeral counter__numeral_big">
                              {home ? home.total_users : <Shimmer data={{ w: 100, h: 20 }} />}
                           </span>
                           <span className="counter__title">Users</span>
                        </div>
                        <div className="counter counters__counter counter_yellow counter_two">
                           <span className="counter__numeral">
                              {home ? `${home.total_invest.toFixed(4)} BTC` : <Shimmer data={{ w: 100, h: 20 }} />}
                           </span>
                           <span className="counter__title">Total deposits</span>
                        </div>
                     </div>
                     <div className="counters__row">
                        <div className="counter counters__counter counter_orange">
                           <span className="counter__numeral">
                              {home ? `${home.total_payment.toFixed(4)} BTC` : <Shimmer data={{ w: 100, h: 20 }} />}
                           </span>
                           <span className="counter__title">Total payouts</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="features">
            <div className="container">
               <h3 className="title-secondary features__title">
                  Our features
               </h3>
               <div className="features__inner">
                  <div className="feature-wrap">
                     <div className="feature features__feature">
                        <div className="feature__header">
                           <div className="feature__icon">
                              <img src="/images/feature-1.svg" alt="" />
                           </div>
                           <div className="feature__title">Automatic payouts</div>
                        </div>
                        <p className="feature_text">
                           All operations are automated. We use fast and fault-tolerant server solutions. Therefore, in our system no delays.
                        </p>
                     </div>
                  </div>
                  <div className="feature-wrap">
                     <div className="feature features__feature">
                        <div className="feature__header">
                           <div className="feature__icon">
                              <img src="/images/feature-2.svg" alt="" />
                           </div>
                           <div className="feature__title">Affiliate programm</div>
                        </div>
                        <p className="feature_text">
                           Get 10% of your partner's deposit to your payment address. All payments are automated.
                        </p>
                     </div>
                  </div>
                  <div className="feature-wrap">
                     <div className="feature features__feature">
                        <div className="feature__header">
                           <div className="feature__icon">
                              <img src="/images/feature-3.svg" alt="" />
                           </div>
                           <div className="feature__title">Professional protection</div>
                        </div>
                        <p className="feature_text">
                           We use strong DDoS protection and a trusted SSL certificate to protect your personal information.
                        </p>
                     </div>
                  </div>
                  <div className="feature-wrap">
                     <div className="feature features__feature">
                        <div className="feature__header">
                           <div className="feature__icon">
                              <img src="/images/feature-4.svg" alt="" />
                           </div>
                           <div className="feature__title">A real company</div>
                        </div>
                        <p className="feature_text">
                           Our company really exists and is under the jurisdiction of the UK.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section id="statistics" className="statistics">
            <div className="container">
               <h2 className="section-title">Statistics</h2>
               <p className="section-descr">Here you can see last deposits and last payouts</p>
               <div className="stat-tabs js-tabs">
                  <div className="stat-tabs__nav">
                     <button className="button button_white stat-tabs__btn js-tab-nav-item active">Last deposits</button>
                     <button className="button button_white stat-tabs__btn js-tab-nav-item">Last payouts</button>
                  </div>
                  <div className="stat-tabs__panes">
                     <div className="stat-tabs__pane js-tab-pane active">
                        <table className="stat-table">
                           <tr>
                              <th>Date</th>
                              <th>Transaction</th>
                              <th>Amount</th>
                              <th>Status</th>
                           </tr>
                           {home?.deposits.map((d) => {
                              return <tr key={d.hash}>
                                 <td style={{ width: '200px' }}>{convertDate(d.time)}</td>
                                 <td style={{ width: '330px', textAlign: 'left', }}>
                                    <img
                                       data-placement="left"
                                       data-toggle="tooltip"
                                       src={`/images/${d.account}.png`}
                                       title={d.account} width="17"
                                       alt=""
                                       style={{ marginRight: '10px' }}
                                    />
                                    <a target="_blank" href={GetUrl(d.account, d.hash)}
                                       style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '380px' }} rel="noreferrer">
                                       {d.hash?.slice(0, 30)}...
                                    </a>
                                 </td>
                                 <td style={{ width: '200px', textAlign: 'left', }}>{d.amount} {GetAccount(d.account)}</td>
                                 <td><i className="fas fa-check" style={{ color: '#27ae60' }}></i> Active</td>
                              </tr>
                           })}
                        </table>
                     </div>
                     <div className="stat-tabs__pane js-tab-pane">
                        <table className="stat-table">
                           <tr>
                              <th>Date</th>
                              <th>TRANSACTION</th>
                              <th>Amount</th>
                              <th>Status</th>
                           </tr>
                           {home?.payments.map((d) => {
                              return <tr key={d.hash}>
                                 <td style={{ width: '200px' }}>{convertDate(d.time)}</td>
                                 <td style={{ width: '330px', textAlign: 'left', }}>
                                    <img
                                       data-placement="left"
                                       data-toggle="tooltip"
                                       src={`/images/${d.account}.png`}
                                       title={d.account} width="17"
                                       alt=""
                                       style={{ marginRight: '10px' }}
                                    />
                                    <a target="_blank" href={GetUrl(d.account, d.hash)}
                                       style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '380px' }} rel="noreferrer">
                                       {d.hash?.slice(0, 30)}...
                                    </a>
                                 </td>
                                 <td style={{ width: '200px', textAlign: 'left', }}>{d.amount} {GetAccount(d.account)}</td>
                                 <td><i className="fas fa-check" style={{ color: '#27ae60' }}></i> Payouted</td>
                              </tr>
                           })}
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section id="faq" className="faq">
            <div className="container">
               <h2 className="section-title">Help</h2>
               <p className="section-descr">In this section You can find the most frequently asked questions.</p>
               <div className="faq__accordion js-accordion">
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">What makes our service unique?</div>
                     <div className="accordion-item__body">
                        We attach great importance to transparency. In Trx5.ltd you can track all deposits and payouts of any user in real time at any time.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">Where does the profit come from?</div>
                     <div className="accordion-item__body">
                        In Trx5.ltd, you don't need to sign contracts or pledge your property. No creditors and no debtors. In fact, it's very simple: one user asks for help, the other helps. All profits are provided by new investments.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">How it's work?</div>
                     <div className="accordion-item__body">
                        For example, you make a deposit of 5 TRX and after 24 hours you will get 5 TRX Bitcoin.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">I want to start earning money. What should I do?</div>
                     <div className="accordion-item__body">
                        1. Provide us with your personal wallet to earn money.<br />2. Make a deposit for the amount you need.<br />3. Wait deposit confirmation.<br />4. After 24 hours, wait earnings on your wallet specified at the registration.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">Do you have commissions?</div>
                     <div className="accordion-item__body">
                        No, we do not charge any fees for the use of our service.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">Is your site secure from hacker attacks?</div>
                     <div className="accordion-item__body">
                        Yes, we use serious DDOS protection and a powerful server to provide you the service available at any time. SSL certificate guarantees the security of all information you enter.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">What cryptocurrencies do you support?</div>
                     <div className="accordion-item__body">
                        We support Tron.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">When will my deposit appear on the home page?</div>
                     <div className="accordion-item__body">
                        The deposit will be created immediately after the confirmation of the transaction in the network of cryptocurrency.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">Can I make more than one deposit?</div>
                     <div className="accordion-item__body">
                        Yes, there are no limits.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">It's time for my payout. When will I get it?</div>
                     <div className="accordion-item__body">
                        Payments order is automated and takes no more than 3 hours.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">What to do if the deposit is less than the minimum amount?</div>
                     <div className="accordion-item__body">
                        Any amount less than the minimum will be considered a donation and will not be displayed as a valid deposit! The maximum amount is not limited. Please pay attention to the minimum amount for each payment system when creating a deposit.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">Do you have an affiliate program?</div>
                     <div className="accordion-item__body">
                        Yes, we give 10 percent for each deposit of your referral, depending on the amount of the deposit.
                     </div>
                  </div>
                  <div className="accordion-item">
                     <div className="accordion-item__btn js-accordion-btn">How can I contact you?</div>
                     <div className="accordion-item__body">
                        If you have any questions, you can contact us at the email address listed at the bottom of the page.
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <footer className="footer">
            <div className="container">
               <div className="footer__inner">
                  <style type="text/css">

                  </style>
                  <div className="footer__col">
                     <a href="/" className="logo footer__logo"><img src="/images/logo.png" alt="Trx5.ltd" /></a>
                  </div>
                  <div className="footer__col">
                     <span className="footer__title">Menu</span>
                     <nav className="footer-nav">
                        <ul className="footer-nav__list js-anchors">
                           <li><a href="#statistics">Statistics</a></li>
                           <li><a href="#faq">Help</a></li>
                        </ul>
                     </nav>
                  </div>
                  <div className="footer__col">
                     <span className="footer__title">Contacts</span>
                     <div className="contacts">
                        <a href="mailto:support@trx5.ltd" target="_blank" className="contacts__link contacts__link_mail" rel="noreferrer">support@trx5.ltd</a>
                        <a href="https://t.me/+AilxS90PX6RkN2Zl" target="_blank" className="contacts__link contacts__link_telegram" rel="noreferrer">Telegram channel</a>
                     </div>
                  </div>
               </div>
            </div>
            <div className="copy footer__copy">Copyright &copy; 2023 Trx5.ltd. All rights reserved.</div>
         </footer>
      </div>
   );
};

export default Home;