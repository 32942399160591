import React, { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import RootLayout from './CAB_RootLayout';
import Cabinet from './CAB_Cabinet';
import Deposits from './CAB_Deposits';
import Payouts from './CAB_Payouts';
import CreateDeposit from './CAB_CreateDeposit';
import Partners from './CAB_Partners';
import { Toaster } from 'react-hot-toast';
import { Time, ValidateWallet } from './Utils';
import axios from 'axios';
import { Configs } from './config';
import { ILoginResponse, IR_Cabinet, IR_Deposit, IR_Partners, IR_Payout } from './ServerResponse';


export interface IMyConext {
   currentTab: 'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners';
   setCurrentTab: React.Dispatch<React.SetStateAction<'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners'>>;
   UpdateUserData: () => void,

   user: IR_Cabinet | null;
   setUser: React.Dispatch<React.SetStateAction<IR_Cabinet | null>>;
   userLast: number;
   setUserLast: React.Dispatch<React.SetStateAction<number>>;

   deposits: IR_Deposit[] | null;
   depositsLast: number;
   setDeposits: React.Dispatch<React.SetStateAction<IR_Deposit[] | null>>;
   setDepositsLast: React.Dispatch<React.SetStateAction<number>>;

   payments: IR_Payout[] | null;
   paymentsLast: number;
   setPayments: React.Dispatch<React.SetStateAction<IR_Payout[] | null>>;
   setPaymentsLast: React.Dispatch<React.SetStateAction<number>>;

   partners: IR_Partners | null;
   partnersLast: number;
   setPartners: React.Dispatch<React.SetStateAction<IR_Partners | null>>;
   setPartnersLast: React.Dispatch<React.SetStateAction<number>>;
}

export const MyContext = createContext<IMyConext>({
   currentTab: 'Home',
   setCurrentTab: () => { },
   UpdateUserData: () => { },

   user: null,
   setUser: () => { },
   userLast: 0,
   setUserLast: () => { },
   deposits: null,
   depositsLast: 0,
   setDeposits: () => { },
   setDepositsLast: () => { },
   payments: null,
   paymentsLast: 0,
   setPayments: () => { },
   setPaymentsLast: () => { },
   partners: null,
   partnersLast: 0,
   setPartners: () => { },
   setPartnersLast: () => { },
})

function App() {
   const [currentTab, setCurrentTab] = useState<'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners'>('Home');

   const [user, setUser] = useState<IR_Cabinet | null>(null);
   const [userLast, setUserLast] = useState<number>(0);
   const [deposits, setDeposits] = useState<IR_Deposit[] | null>(null);
   const [depositsLast, setDepositsLast] = useState<number>(0);
   const [payments, setPayments] = useState<IR_Payout[] | null>(null);
   const [paymentsLast, setPaymentsLast] = useState<number>(0);
   const [partners, setPartners] = useState<IR_Partners | null>(null);
   const [partnersLast, setPartnersLast] = useState<number>(0);

   const UpdateUserData = () => {
      setUser(null);
      (async () => {
         try {
            const wallet = localStorage.getItem('wallet');
            if (!wallet || !ValidateWallet(wallet)) return;
            const data = (await axios.post(`${Configs.API_URL}/auth`, { wallet })).data as ILoginResponse;
            if (!data.data.wallet) return;
            setUser(data.data);
            setUserLast(Time() + 5);
         }
         catch (e) {
            return;
         }
      })();
   }

   return (
      <>
         <Toaster position='top-center' toastOptions={{ duration: 2000 }} />
         <MyContext.Provider value={{ currentTab, setCurrentTab, UpdateUserData, user, setUser, userLast, setUserLast, deposits, depositsLast, setDeposits, setDepositsLast, payments, paymentsLast, setPayments, setPaymentsLast, partners, partnersLast, setPartners, setPartnersLast }}>
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/cabinet" element={<RootLayout />}>
                  <Route index element={<Cabinet />} />
                  <Route path="deposits" element={<Deposits />} />
                  <Route path="payouts" element={<Payouts />} />
                  <Route path="partners" element={<Partners />} />
                  <Route path="createDeposit" element={<CreateDeposit />} />
               </Route>
               <Route path="/:refCode" element={<Home />} />
               <Route path="*" element={<Home />} />
            </Routes>
         </MyContext.Provider>
      </>
   );
}

export default App;
