import { Outlet } from 'react-router-dom';
import Header from './CAB_Header';
import Footer from './CAB_Footer';

const RootLayout = () => {
   return (
      <div className="profile-page">
         <div className="wrapper" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />
            <Outlet />
            <Footer />
         </div>
      </div>
   );
};

export default RootLayout;